import React, { useEffect, useState } from 'react';
import { SVG } from '@grant/wonderscope-common/components';
import { SliceConfig } from '@grant/wonderscope-toolkit/components';
import cn from 'classnames';
import * as styles from './styles.module.scss';

const Footer = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) return null;

  return (
    <div className={styles.container}>
      <SliceConfig config={{ backgroundColor: { hex: 'var(--color-cream)' } }}>
        <SVG svg="wordmark" className={styles.wordmark} />

        <div className={cn('caption', styles.bottomText)}>
          <div className={styles.left}>
            <p>© Wonderscope {new Date().getFullYear()}</p>
            <p>
              Follow us on{' '}
              <a
                href="https://www.instagram.com/discover.wonderscope/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Instagram
              </a>
            </p>
          </div>
          <p>
            Made with{' '}
            <a
              className={styles.lamLink}
              href="https://www.loveandmoney.agency/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Love + Money
            </a>
          </p>
        </div>
      </SliceConfig>
    </div>
  );
};

export default Footer;
