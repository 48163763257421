import React from 'react';
import cn from 'classnames';
import { SVG } from '@grant/wonderscope-common/components';
import * as styles from './styles.module.scss';

interface IProps {
  id?: string;
  setFile: (file: File | null) => void;
  label?: string;
  hint?: string;
  warningMessage?: string;
  hasError?: boolean;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  acceptedFileFormats?: string;
}

const FileUpload = ({
  id,
  setFile,
  label,
  hint,
  warningMessage,
  hasError,
  required,
  disabled,
  className,
  acceptedFileFormats = `application/pdf`
}: IProps) => {
  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] || null);
  };

  const warningIconColor = `var(--color-black-40)`;

  return (
    <div
      className={cn(styles.container, className, {
        [styles.error]: hasError,
        [styles.disabled]: disabled
      })}
    >
      {(label || hint || required) && (
        <div className={styles.topText}>
          <div>
            {(label || required) && (
              <label className={cn(`caption`)} htmlFor={id}>
                {required && `* `}
                {label}
              </label>
            )}
          </div>
          <div>
            {hint && <span className={cn(`caption`, styles.hint)}>{hint}</span>}
          </div>
        </div>
      )}

      <input
        className={cn(`b2`, styles.fileUpload)}
        type="file"
        accept={acceptedFileFormats}
        onChange={handleChangeFile}
        disabled={disabled}
      />

      {warningMessage && hasError && (
        <span className={cn(`caption`, styles.warning)}>
          <SVG
            svg="warningTriangle"
            className={styles.icon}
            style={{
              color: hasError ? `var(--color-red)` : warningIconColor
            }}
          />

          {warningMessage}
        </span>
      )}
    </div>
  );
};

export default FileUpload;
