import React, { useEffect, useRef, useState } from 'react';
import { IFont } from '@grant/wonderscope-sanity';
import { EditableTextField } from '@grant/wonderscope-toolkit/components';
import {
  Button,
  SVG,
  LoadingSpinner
} from '@grant/wonderscope-common/components';
import cn from 'classnames';
import { useASG } from '@grant/wonderscope-toolkit/hooks';
import {
  IDeleteSanityFontRequestBody,
  IUpdateSanityFontRequestBody
} from '@grant/wonderscope-toolkit/api';
import * as styles from './styles.module.scss';

interface IProps {
  font: IFont;
}

interface IFormErrors {
  title: boolean;
  fontWeight: boolean;
}

interface IFormValues {
  title: string;
  fontWeight: string;
}

type TState = `idle` | `editing` | `updating` | `deleteMenu` | `deleting`;

const FontItem = ({ font }: IProps) => {
  const prevFontValues = useRef(font);

  const initialFormValues: IFormValues = {
    title: font.title,
    fontWeight: font.fontWeight
  };

  const initialFormErrors: IFormErrors = {
    title: false,
    fontWeight: false
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [state, setState] = useState<TState>(`idle`);

  const isEditing = state === `editing` || state === `updating`;

  const buttonText = isEditing ? `Save` : `Edit`;

  useEffect(() => {
    setFormErrors(initialFormErrors);
  }, [formValues]);

  const haveValuesChanged = () => {
    if (formValues.title !== prevFontValues.current.title) {
      return true;
    }
    if (formValues.fontWeight !== prevFontValues.current.fontWeight) {
      return true;
    }
    return false;
  };

  const checkValidation = () => {
    const newErrors = { ...formErrors };

    // Title can be anything
    if (!formValues.title) {
      newErrors.title = true;
    }
    // Font weight needs to be digits only, at least one
    if (!/^\d+$/.test(formValues.fontWeight)) {
      newErrors.fontWeight = true;
    }

    setFormErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      return false;
    }

    return true;
  };

  const { setFonts, isAuthenticated } = useASG();

  const updateFont = async () => {
    setState(`updating`);

    try {
      const updatedFontData: IUpdateSanityFontRequestBody = {
        title: formValues.title,
        fontWeight: formValues.fontWeight,
        _id: font._id!
      };

      const response = await fetch(`/api/update-sanity-font`, {
        method: `POST`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedFontData)
      });

      const data = await response.json();

      if (data.statusCode === 500) {
        throw new Error(`Something went wrong submitting form: ${data.body}`);
      }

      const updatedFont: IFont = {
        ...font,
        title: data.title,
        fontWeight: data.fontWeight
      };

      setFonts((prev) =>
        prev.map((font) => {
          if (font._id === data._id) {
            return updatedFont;
          }

          return font;
        })
      );

      prevFontValues.current = updatedFont;
      setState(`idle`);
    } catch (error) {
      console.error(error);
      setState(`editing`);
    }
  };

  const handleMainButton = () => {
    if (state === `idle`) {
      setState(`editing`);
      return;
    }

    if (!haveValuesChanged()) {
      setState(`idle`);
      return;
    }

    if (checkValidation()) {
      updateFont();
    }
  };

  const deleteFont = async () => {
    setState(`deleting`);

    const fontId = font._id;

    try {
      const deleteSanityDocumentReqBody: IDeleteSanityFontRequestBody = {
        fontDocumentId: font._id,
        woff2AssetId: font.woff2.asset._id,
        woffAssetId: font.woff.asset._id
      };

      const response = await fetch(`/api/delete-sanity-font`, {
        method: `POST`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(deleteSanityDocumentReqBody)
      });

      const data = await response.json();

      if (data.statusCode === 500) {
        throw new Error(`Something went wrong submitting form: ${data.body}`);
      }

      setFonts((prev) => prev.filter((font) => font._id !== fontId));
    } catch (error) {
      console.error(error);
      setState(`deleteMenu`);
    }
  };

  const isDeleteMenuVisible = state === `deleteMenu` || state === `deleting`;

  return (
    <div className={styles.container}>
      <div className={styles.formSection}>
        <label className={styles.label}>
          Title:
          <EditableTextField
            error={formErrors.title}
            isResetEnabled={formValues.title !== prevFontValues.current.title}
            onReset={() =>
              setFormValues((prev) => ({
                ...prev,
                title: prevFontValues.current.title
              }))
            }
            className={styles.title}
            value={formValues.title}
            isEditing={state === `editing`}
            style={{ fontFamily: font.title, fontWeight: font.fontWeight }}
            onChange={(value) =>
              setFormValues((prev) => ({ ...prev, title: value }))
            }
          />
        </label>
      </div>

      <div className={styles.formSection}>
        <label className={styles.label}>
          Font Weight:
          <EditableTextField
            error={formErrors.fontWeight}
            isResetEnabled={
              formValues.fontWeight !== prevFontValues.current.fontWeight
            }
            onReset={() =>
              setFormValues((prev) => ({
                ...prev,
                fontWeight: prevFontValues.current.fontWeight
              }))
            }
            value={formValues.fontWeight}
            isEditing={state === `editing`}
            onChange={(value) =>
              setFormValues((prev) => ({ ...prev, fontWeight: value }))
            }
          />
        </label>
      </div>

      {/* <div className={cn(styles.formSection, styles.fontUploadSection)}>
        <label className={styles.label}>
          WOFF2 File:
          <p className={cn(`b2`)}>{font.woff2.asset.originalFilename}</p>
          <FileUpload
            className={cn(styles.fileUpload, { [styles.visible]: isEditing })}
            acceptedFileFormats=".woff2"
            disabled={state !== `editing`}
            setFile={(file) =>
              setFormValues((prev) => ({ ...prev, woff2: file }))
            }
          />
        </label>

        <label className={styles.label}>
          WOFF File:
          <p className={cn(`b2`)}>{font.woff.asset.originalFilename}</p>
          <FileUpload
            className={cn(styles.fileUpload, { [styles.visible]: isEditing })}
            acceptedFileFormats=".woff"
            disabled={state !== `editing`}
            setFile={(file) =>
              setFormValues((prev) => ({ ...prev, woff: file }))
            }
          />
        </label>
      </div> */}

      <div className={styles.buttons}>
        {isAuthenticated && (
          <Button
            loading={state === `updating`}
            onClick={handleMainButton}
            text={buttonText}
          />
        )}

        {isEditing && (
          <button
            className={styles.deleteButton}
            disabled={state === `updating`}
            onClick={() => setState(`deleteMenu`)}
          >
            <SVG svg="bin" className={styles.deleteIcon} />
          </button>
        )}
      </div>

      {/* Delete menu */}
      <div
        aria-hidden={!isDeleteMenuVisible}
        className={cn(styles.deleteMenu, {
          [styles.visible]: isDeleteMenuVisible
        })}
      >
        <p className={cn(`h3`, styles.deleteTitle)}>Delete font?</p>
        <div className={styles.deleteButtons}>
          <Button
            disabled={state === `deleting`}
            variant="text"
            onClick={deleteFont}
            text="Yes"
          />
          <Button
            disabled={state === `deleting`}
            variant="text"
            onClick={() => setState(`idle`)}
            text="No"
          />
        </div>
        <LoadingSpinner
          className={cn(styles.spinner, {
            [styles.visible]: state === `deleting`
          })}
        />
      </div>
    </div>
  );
};

export default FontItem;
