import React from 'react';
import { Helmet } from 'react-helmet';
import { useSiteMetadata } from '@grant/wonderscope-toolkit/hooks';

interface IProps {
  pageTitle: string;
}

const SEO = ({ pageTitle }: IProps) => {
  const meta = useSiteMetadata();
  const siteTitle = meta.title;
  const titleText =
    pageTitle === `Home` ? siteTitle : `${pageTitle} | ${siteTitle}`;

  const favicon = '/images/favicon.png';

  return (
    <>
      <Helmet>
        <title>{titleText}</title>
        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
    </>
  );
};

export default SEO;
