import React from 'react';
import { SliceConfig } from '@grant/wonderscope-toolkit/components';
import {
  IntersectionAnimation,
  LayoutProvider,
  PortableTextRenderer
} from '@grant/wonderscope-common/components';
import { ITypePreview } from '@grant/wonderscope-sanity';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import * as styles from './styles.module.scss';

interface IProps {
  data: ITypePreview;
}

const TypePreview = ({
  data: {
    _rawContent,
    fontSize,
    fontWeight,
    letterSpacing,
    lineHeight,
    style,
    sliceConfig
  }
}: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  return (
    <SliceConfig config={sliceConfig}>
      <div ref={ref}>
        <LayoutProvider grid>
          <IntersectionAnimation
            trigger={inView}
            className={styles.titleContainer}
          >
            <h4 className={cn('b1')}>{style}</h4>
          </IntersectionAnimation>
          <IntersectionAnimation
            className={styles.contentContainer}
            trigger={inView}
            delay={300}
          >
            <div className={styles.overflowContainer}>
              <PortableTextRenderer
                rawText={_rawContent}
                className={styles.content}
                style={{
                  fontSize,
                  fontWeight,
                  lineHeight,
                  letterSpacing
                }}
              />
            </div>
          </IntersectionAnimation>
          <IntersectionAnimation className={cn(`b1`, styles.properties)}>
            <div>{fontWeight}</div>
            <div>{fontSize}</div>
            <div>{lineHeight}</div>
            <div>{letterSpacing}</div>
          </IntersectionAnimation>
        </LayoutProvider>
      </div>
    </SliceConfig>
  );
};

export default TypePreview;
