import React from 'react';
import cn from 'classnames';
import { hexToRGB, rgbToCMYK } from '@grant/wonderscope-common/utils';
import { CopyText } from '@grant/wonderscope-toolkit/components';
import * as styles from './styles.module.scss';

interface IProps {
  title: string;
  hex: string;
  textColor?: string;
  pantone?: string;
  pairTile?: boolean;
  isBackgroundColor?: boolean;
}

const ColorGridTile = ({
  hex,
  title,
  pantone,
  textColor,
  pairTile,
  isBackgroundColor
}: IProps) => {
  const rgb = hexToRGB(hex);
  const { red, green, blue } = rgb;

  const { c, m, y, k } = rgbToCMYK(rgb);

  const getTextColor = () => {
    if (textColor) {
      return textColor;
    }

    const luminance =
      (0.299 * parseFloat(red) +
        0.587 * parseFloat(green) +
        0.114 * parseFloat(blue)) /
      255;

    if (luminance > 0.5) {
      return 'var(--color-black)';
    }
    return 'var(--color-white)';
  };

  const getBorderStyles = () => {
    if (isBackgroundColor) {
      return {
        border: `1px solid ${getTextColor()}`
      };
    }
    return {
      border: `1px solid ${hex}`
    };
  };

  return (
    <div
      className={styles.container}
      style={{ color: getTextColor(), background: hex, ...getBorderStyles() }}
    >
      <h3 className={cn('h2')}>{title}</h3>
      <div className={cn('b2', styles.bottomText)}>
        <div className={styles.bottomLeftEdge}>
          <CopyText text={hex} />
          <CopyText text={`R ${red} G ${green} B ${blue}`} />
        </div>

        {pairTile && (
          <div className={styles.bottomRightEdge}>
            <CopyText text={`C ${c} M ${m} Y ${y} K ${k}`} />

            {pantone && <CopyText text={pantone} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorGridTile;
