import React, { CSSProperties, useRef, useState } from 'react';
import cn from 'classnames';
import { ITypestyle } from '@grant/wonderscope-sanity';
import { EditableTextField } from '@grant/wonderscope-toolkit/components';
import { Button } from '@grant/wonderscope-common/components';
import { useASG } from '@grant/wonderscope-toolkit/hooks';
import { TActiveBreakpoint } from '../..';
import * as styles from './styles.module.scss';

interface IProps {
  className?: string;
  typestyle: ITypestyle;
  activeBreakpoint: TActiveBreakpoint;
  setTypestyles: React.Dispatch<React.SetStateAction<ITypestyle[]>>;
}

const TypestyleRow = ({
  className,
  typestyle,
  activeBreakpoint,
  setTypestyles
}: IProps) => {
  const [isEditing, setIsEditing] = useState(false); // todo - setup useEditableTextField hook
  const [isUpdatingTypestyle, setIsUpdatingTypestyle] = useState(false);

  const { isAuthenticated, getFontById } = useASG();

  const prevTypestyleValues = useRef(typestyle);

  const updateFontSizes = (value: string) => {
    setTypestyles((prev) =>
      prev.map((mappedTypestyle) => {
        if (mappedTypestyle._id === typestyle._id) {
          return {
            ...mappedTypestyle,
            fontSizes: {
              ...mappedTypestyle.fontSizes,
              [activeBreakpoint]: value
            }
          };
        }

        return mappedTypestyle;
      })
    );
  };

  const updateLineHeght = (value: string) => {
    setTypestyles((prev) =>
      prev.map((mappedTypestyle) => {
        if (mappedTypestyle._id === typestyle._id) {
          return {
            ...mappedTypestyle,
            lineHeight: value
          };
        }

        return mappedTypestyle;
      })
    );
  };

  const updateLetterSpacing = (value: string) => {
    setTypestyles((prev) =>
      prev.map((mappedTypestyle) => {
        if (mappedTypestyle._id === typestyle._id) {
          return {
            ...mappedTypestyle,
            letterSpacing: value
          };
        }

        return mappedTypestyle;
      })
    );
  };

  const resetFontSize = () => {
    setTypestyles((prev) =>
      prev.map((mappedTypestyle) => {
        if (mappedTypestyle._id === typestyle._id) {
          return {
            ...mappedTypestyle,
            fontSizes: {
              ...mappedTypestyle.fontSizes,
              [activeBreakpoint]:
                prevTypestyleValues.current.fontSizes[activeBreakpoint]
            }
          };
        }

        return mappedTypestyle;
      })
    );
  };

  const resetLineHeight = () => {
    setTypestyles((prev) =>
      prev.map((mappedTypestyle) => {
        if (mappedTypestyle._id === typestyle._id) {
          return {
            ...mappedTypestyle,
            lineHeight: prevTypestyleValues.current.lineHeight
          };
        }

        return mappedTypestyle;
      })
    );
  };

  const resetLetterSpacing = () => {
    setTypestyles((prev) =>
      prev.map((mappedTypestyle) => {
        if (mappedTypestyle._id === typestyle._id) {
          return {
            ...mappedTypestyle,
            letterSpacing: prevTypestyleValues.current.letterSpacing
          };
        }

        return mappedTypestyle;
      })
    );
  };

  const updateTypestyle = async () => {
    setIsUpdatingTypestyle(true);

    try {
      const response = await fetch(`/api/update-sanity-typestyle`, {
        method: `POST`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          typestyle
        })
      });

      const data = await response.json();

      if (data.statusCode === 500) {
        throw new Error(`Something went wrong submitting form: ${data.body}`);
      }

      const updatedTypestyle: ITypestyle = {
        ...typestyle,
        fontSizes: data.fontSizes,
        lineHeight: data.lineHeight,
        letterSpacing: data.letterSpacing
      };

      setTypestyles((prev) =>
        prev.map((mappedTypestyle) => {
          if (mappedTypestyle._id === data._id) {
            return updatedTypestyle;
          }

          return mappedTypestyle;
        })
      );

      prevTypestyleValues.current = updatedTypestyle;
      setIsEditing(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUpdatingTypestyle(false);
    }
  };

  const saveChanges = () => {
    const getHasFontSizeBeenUpdated = () => {
      const breakpoints: TActiveBreakpoint[] = [`desktop`, `tablet`, `mobile`];

      for (let i = 0; i < breakpoints.length; i++) {
        if (
          typestyle.fontSizes[breakpoints[i]] !==
          prevTypestyleValues.current.fontSizes[breakpoints[i]]
        ) {
          return true;
        }
      }

      return false;
    };

    typestyle.fontSizes !== prevTypestyleValues.current.fontSizes;

    const hasFontSizeBeenUpdated = getHasFontSizeBeenUpdated();

    const hasLineHeightBeenUpdated =
      typestyle.lineHeight !== prevTypestyleValues.current.lineHeight;

    const hasLetterSpacingBeenUpdated =
      typestyle.lineHeight !== prevTypestyleValues.current.lineHeight;

    if (
      !hasFontSizeBeenUpdated &&
      !hasLineHeightBeenUpdated &&
      !hasLetterSpacingBeenUpdated
    ) {
      setIsEditing(false);
      return;
    }

    updateTypestyle();
  };

  const handleClickButton = () => {
    if (isEditing) {
      saveChanges();
    } else {
      setIsEditing(true);
    }
  };

  const typestyleStyles: () => CSSProperties = () => {
    const { fontSizes, uppercase } = prevTypestyleValues.current;

    const font = getFontById(typestyle.font._id);
    if (!font) return {};

    return {
      fontSize: fontSizes[activeBreakpoint],
      lineHeight: fontSizes[`${activeBreakpoint}LineHeight`],
      letterSpacing: fontSizes[`${activeBreakpoint}LetterSpacing`],
      textTransform: uppercase ? `uppercase` : `none`,
      fontFamily: font.title
    };
  };

  const buttonText = isEditing ? `Save` : `Edit`;
  const buttonVariant = isEditing ? `primary` : `secondary`;

  const hasFontSizeChanged =
    typestyle.fontSizes[activeBreakpoint] !==
    prevTypestyleValues.current.fontSizes[activeBreakpoint];

  const hasLineHeightChanged =
    typestyle.lineHeight !== prevTypestyleValues.current.lineHeight;

  const hasLetterSpacingChanged =
    typestyle.letterSpacing !== prevTypestyleValues.current.letterSpacing;

  return (
    <div className={cn(styles.container, className)}>
      <div style={typestyleStyles()} className={styles.title}>
        {typestyle.title}
      </div>

      <div className={styles.fontProperties}>
        <div className={styles.propertyField}>
          <EditableTextField
            isEditing={isEditing && !isUpdatingTypestyle}
            value={typestyle.fontSizes[activeBreakpoint]}
            onReset={resetFontSize}
            onChange={updateFontSizes}
            isResetEnabled={hasFontSizeChanged}
          />
        </div>
        <div className={styles.propertyField}>
          <EditableTextField
            isEditing={isEditing && !isUpdatingTypestyle}
            value={typestyle.fontSizes[`${activeBreakpoint}LineHeight`]}
            onReset={resetLineHeight}
            onChange={updateLineHeght}
            isResetEnabled={hasLineHeightChanged}
          />
        </div>
        <div className={styles.propertyField}>
          <EditableTextField
            isEditing={isEditing && !isUpdatingTypestyle}
            value={typestyle.fontSizes[`${activeBreakpoint}LetterSpacing`]}
            onReset={resetLetterSpacing}
            onChange={updateLetterSpacing}
            isResetEnabled={hasLetterSpacingChanged}
          />
        </div>
      </div>

      {isAuthenticated && (
        <div className={styles.button}>
          <Button
            onClick={handleClickButton}
            variant={buttonVariant}
            loading={isUpdatingTypestyle}
            text={buttonText}
          />
        </div>
      )}
    </div>
  );
};

export default TypestyleRow;
