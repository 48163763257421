import React from 'react';
import { useApp } from '@grant/wonderscope-toolkit/hooks';
import cn from 'classnames';
import { PageLink } from '@grant/wonderscope-toolkit/components';
import { TPage } from 'src/hooks/usePageNavigation';
import * as styles from './styles.module.scss';

interface IProps {
  title: string;
  pages: TPage[];
}

const PageCategoryGroup = ({ title: categoryTitle, pages }: IProps) => {
  const { pathname } = useApp();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h4 className={cn(styles.heading, `h2`)}>{categoryTitle}</h4>

        <ul className={styles.list}>
          {pages.map(({ title, slug, id, disabled }) => {
            const isActivePage = pathname?.includes(slug.current);

            return (
              <li key={id} className={styles.link}>
                <PageLink
                  disabled={disabled}
                  title={title}
                  isActivePage={isActivePage}
                  slug={slug}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default PageCategoryGroup;
