import React, { useState } from 'react';
import { Button } from '@grant/wonderscope-common/components';
import { camelCaseToTitleCase } from '@grant/wonderscope-common/utils';
import { IWebComponents } from '@grant/wonderscope-sanity';
import { SliceConfig } from '@grant/wonderscope-toolkit/components';
import cn from 'classnames';
import {
  PGComponents,
  TPGComponent
} from '@grant/wonderscope-toolkit/playground';
import * as styles from './styles.module.scss';

interface IProps {
  data: IWebComponents;
}

const WebComponents = ({ data: { sliceConfig } }: IProps) => {
  const playgroundComponents = Object.keys(PGComponents) as TPGComponent[];

  const [activeComponent, setActiveComponent] = useState<TPGComponent>(
    playgroundComponents[0]
  );

  const PlaygroundElement = PGComponents[activeComponent];

  return (
    <SliceConfig config={sliceConfig}>
      <h2 className={cn(`h3`, styles.selectText)}>Select Component</h2>
      <div className={styles.selectComponentButtons}>
        {playgroundComponents.map((component, i) => (
          <Button
            text={camelCaseToTitleCase(component)}
            key={i}
            variant={activeComponent === component ? `primary` : `secondary`}
            onClick={() => setActiveComponent(component)}
          ></Button>
        ))}
      </div>

      <PlaygroundElement />
    </SliceConfig>
  );
};

export default WebComponents;
