import React from 'react';
import { SliceConfig } from '@grant/wonderscope-toolkit/components';
import { useApp, usePageNavigation } from '@grant/wonderscope-toolkit/hooks';
import cn from 'classnames';
import PageNavButton from './components/PageNavButton';
import * as styles from './styles.module.scss';

const PageNavButtons = () => {
  const { pathname } = useApp();

  const { getPrevPage, getNextPage } = usePageNavigation();

  const prevPage = getPrevPage(pathname);
  const nextPage = getNextPage(pathname);

  const getUrl = (slug: string) => {
    if (slug === '/') {
      return '/';
    }
    return `/${slug}`;
  };

  return (
    <nav id="nav" className={cn(styles.container)}>
      <SliceConfig>
        <div className={styles.grid}>
          {prevPage && (
            <PageNavButton
              direction="prev"
              className={styles.prevButton}
              to={getUrl(prevPage.slug.current)}
              text={prevPage.title}
            />
          )}
          {nextPage && (
            <PageNavButton
              direction="next"
              className={cn(styles.nextButton, {
                [styles.noPrevButton]: !prevPage
              })}
              to={getUrl(nextPage.slug.current)}
              text={nextPage.title}
            />
          )}
        </div>
      </SliceConfig>
    </nav>
  );
};

export default PageNavButtons;
