import React from 'react';
import {
  LayoutProvider,
  IntersectionAnimation
} from '@grant/wonderscope-common/components';
import { usePageNavigation, useApp } from '@grant/wonderscope-toolkit/hooks';
import { useInView } from 'react-intersection-observer';
import cn from 'classnames';
import { IMenuList } from '@grant/wonderscope-sanity';
import { PageLink, SliceConfig } from '@grant/wonderscope-toolkit/components';
import * as styles from './styles.module.scss';

interface IProps {
  data: IMenuList;
}

const Menulist = ({ data: { sliceConfig } }: IProps) => {
  const { pageCategories } = usePageNavigation();
  const { pathname } = useApp();

  const { ref, inView } = useInView({
    rootMargin: `-90px`
  });

  return (
    <nav ref={ref}>
      <SliceConfig config={sliceConfig}>
        {pageCategories?.map((category, i) => (
          <IntersectionAnimation key={i} delay={i * 300} trigger={inView}>
            <div key={category.id} className={styles.categoryGroup}>
              <LayoutProvider grid>
                <h4 className={cn(`h2`, styles.categoryTitle)}>
                  {category.title}
                </h4>

                <ul className={styles.links}>
                  {category.pages.map((page) => {
                    const isActivePage = pathname?.includes(page.slug.current);

                    return (
                      <li key={page.id} className={styles.listItem}>
                        <PageLink
                          disabled={page.disabled}
                          title={page.title}
                          isActivePage={isActivePage}
                          slug={page.slug}
                        />
                      </li>
                    );
                  })}
                </ul>
              </LayoutProvider>
            </div>
          </IntersectionAnimation>
        ))}
      </SliceConfig>
    </nav>
  );
};

export default Menulist;
