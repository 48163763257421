import React from 'react';
import { IHomepageAtf } from '@grant/wonderscope-sanity';
import { SliceConfig } from '@grant/wonderscope-toolkit/components';
import * as styles from './styles.module.scss';
import Eyes from './components/Eyes';
import { SVG } from '@grant/wonderscope-common/components';

export interface ISettings {
  characters: string;
  textColor: string;
  backgroundColor: string;
  blendMode: boolean;
}
interface IProps {
  data: IHomepageAtf;
}

const Hero = ({ data: { sliceConfig } }: IProps) => {
  return (
    <div className={styles.container}>
      <SliceConfig config={sliceConfig}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <SVG svg="toolkitAtf" className={styles.toolkitSvg} />

            <div className={styles.eyesContainer}>
              <Eyes />
            </div>
          </div>
        </div>
      </SliceConfig>
    </div>
  );
};

export default Hero;
