import React, { useState } from 'react';
import cn from 'classnames';
import { SliceConfig } from '@grant/wonderscope-toolkit/components';
import { Button } from '@grant/wonderscope-common/components';
import { useASG } from '@grant/wonderscope-toolkit/hooks';
import { ITypestyles } from '@grant/wonderscope-sanity';
import TypestyleRow from './components/TypestyleRow';
import * as styles from './styles.module.scss';

interface IProps {
  data: ITypestyles;
}

export type TActiveBreakpoint = `desktop` | `tablet` | `mobile`;

const Typestyles = ({ data: { sliceConfig } }: IProps) => {
  const [activeBreakpoint, setActiveBreakpoint] =
    useState<TActiveBreakpoint>(`mobile`);

  const { typestyles, setTypestyles } = useASG();

  return (
    <SliceConfig config={sliceConfig}>
      <h2 className={cn(`h1`, styles.title)}>Typestyles</h2>
      <div className={styles.grid}>
        <div className={styles.breakpointButtons}>
          <Button
            variant={activeBreakpoint === `mobile` ? `primary` : `secondary`}
            onClick={() => setActiveBreakpoint(`mobile`)}
            text="Mobile"
          />

          <Button
            variant={activeBreakpoint === `tablet` ? `primary` : `secondary`}
            onClick={() => setActiveBreakpoint(`tablet`)}
            text="Tablet"
          />
          <Button
            variant={activeBreakpoint === `desktop` ? `primary` : `secondary`}
            onClick={() => setActiveBreakpoint(`desktop`)}
            text="Desktop"
          />
        </div>

        <div className={cn('b1', styles.columnHeadings)}>
          <span className={styles.columnHeading}>Font-size</span>
          <span className={styles.columnHeading}>Line-height</span>
          <span className={styles.columnHeading}>Letter-spacing</span>
        </div>
      </div>

      {typestyles.map((typestyle) => (
        <TypestyleRow
          key={typestyle._id}
          activeBreakpoint={activeBreakpoint}
          typestyle={typestyle}
          className={styles.typestyleRow}
          setTypestyles={setTypestyles}
        />
      ))}
    </SliceConfig>
  );
};

export default Typestyles;
