import React from 'react';
import { SliceConfig } from '@grant/wonderscope-toolkit/components';
import { IntersectionAnimation } from '@grant/wonderscope-common/components';
import { IFontFeature } from '@grant/wonderscope-sanity';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import * as styles from './styles.module.scss';

interface IProps {
  data: IFontFeature;
}

const FontFeature = ({
  data: { text, fontSize, fontWeight, typestyle, sliceConfig }
}: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: '-90px',
    triggerOnce: true
  });

  const { font, className, lineHeight, letterSpacing } = typestyle;

  return (
    <div className={styles.container} ref={ref}>
      <SliceConfig config={sliceConfig}>
        <div className={styles.grid}>
          <IntersectionAnimation trigger={inView}>
            <h4 className={cn('b1')}>{font.title}</h4>
          </IntersectionAnimation>
          <IntersectionAnimation
            className={styles.text}
            trigger={inView}
            delay={150}
          >
            <div className={cn(className)} style={{ fontSize }}>
              {text}
            </div>
          </IntersectionAnimation>
          <IntersectionAnimation trigger={inView} delay={300}>
            <div className={cn('b1', styles.properties)}>
              <div>{fontWeight}</div>
              <div>{fontSize}</div>
              <div>{lineHeight}</div>
              <div>{letterSpacing}</div>
            </div>
          </IntersectionAnimation>
        </div>
      </SliceConfig>
    </div>
  );
};

export default FontFeature;
