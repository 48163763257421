import React from 'react';
import { SliceConfig } from '@grant/wonderscope-toolkit/components';
import {
  IntersectionAnimation,
  PortableTextRenderer
} from '@grant/wonderscope-common/components';
import { ITextColumns } from '@grant/wonderscope-sanity';
import { useInView } from 'react-intersection-observer';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  data: ITextColumns;
}

const TextColumns = ({ data: { sliceConfig, textColumns } }: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  const isThreeColumnLayout = textColumns.length === 3;

  return (
    <SliceConfig
      config={{
        ...sliceConfig,
        slicePadding: {
          paddingTop: 'none',
          paddingBottom: 'none',
          paddingX: 'none'
        }
      }}
    >
      <div
        ref={ref}
        className={cn(styles.grid, {
          [styles.threeColumns]: isThreeColumnLayout
        })}
      >
        {textColumns.map((column, i) => (
          <div className={cn(styles.column)} key={i}>
            <SliceConfig config={{ ...sliceConfig, bottomBorder: false }}>
              <IntersectionAnimation trigger={inView} delay={i * 150}>
                <PortableTextRenderer
                  className={styles.leftColumn}
                  rawText={column._rawLeftColumn}
                />
                <PortableTextRenderer rawText={column._rawRightColumn} />
              </IntersectionAnimation>
            </SliceConfig>
          </div>
        ))}
      </div>
    </SliceConfig>
  );
};

export default TextColumns;
