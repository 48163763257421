import { ITypestyle } from '@grant/wonderscope-sanity';
import { useASG } from '@grant/wonderscope-toolkit/hooks';

const getTypestyleCss = (typestyles: ITypestyle[] = []) => {
  const MIN_TABLET_SIZE = 768;
  const MIN_DESKTOP_SIZE = 1024;

  const { getFontById } = useASG();

  let css = ``;

  typestyles.forEach((typestyle) => {
    const font = getFontById(typestyle.font._id);
    if (!font) return;

    css += `
      .${typestyle.className} {
        font-family: ${font?.title};
        font-size: ${typestyle.fontSizes.mobile};
        line-height: ${typestyle.fontSizes.mobileLineHeight};
        letter-spacing: ${typestyle.fontSizes.mobileLetterSpacing};
        text-transform: ${typestyle.uppercase ? 'uppercase' : 'none'};
      }
      @media (min-width: ${MIN_TABLET_SIZE}px) {
        .${typestyle.className} {
          font-size: ${typestyle.fontSizes.tablet};
          line-height: ${typestyle.fontSizes.tabletLineHeight};
          letter-spacing: ${typestyle.fontSizes.tabletLetterSpacing};
        }
      }
      @media (min-width: ${MIN_DESKTOP_SIZE}px) {
        .${typestyle.className} {
          font-size: ${typestyle.fontSizes.desktop};
          line-height: ${typestyle.fontSizes.desktopLineHeight};
          letter-spacing: ${typestyle.fontSizes.desktopLetterSpacing};
        }
      }
    `;
  });

  return css;
};

export default getTypestyleCss;
