import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import * as styles from './styles.module.scss';

const Eyes = () => {
  const leftPupilRef = useRef<HTMLDivElement>(null);
  const rightPupilRef = useRef<HTMLDivElement>(null);

  const updateEyePosition = ({
    mouseX,
    mouseY
  }: {
    mouseX: number;
    mouseY: number;
  }) => {
    const windowWidth = window.innerWidth;
    const minAnimationWidth = 587;
    if (windowWidth < minAnimationWidth) return;

    if (!leftPupilRef.current || !rightPupilRef.current) return;

    const eye = leftPupilRef.current.parentElement;

    const containerRect = eye?.parentElement?.getBoundingClientRect();
    if (!containerRect) return;

    const containerCenterX = containerRect.left + containerRect.width / 2;
    const containerCenterY = containerRect.top + containerRect.height / 2;
    const deltaX = mouseX - containerCenterX;
    const deltaY = mouseY - containerCenterY;
    const angle = Math.atan2(deltaY, deltaX);

    const eyeRect = eye?.getBoundingClientRect();
    if (!eyeRect) return;

    const radiusMult = 0.2; // Adjust based on the eye size to control the distance from the center to the edge
    const maxRadius = (eyeRect.width / 2) * radiusMult;

    const pupilX = maxRadius * Math.cos(angle);
    const pupilY = maxRadius * Math.sin(angle);

    [leftPupilRef, rightPupilRef].forEach((pupilRef) => {
      if (!pupilRef.current) return;

      const left = `${50 + (pupilX / containerRect.height) * 100}%`;
      const top = `${50 + (pupilY / containerRect.height) * 100}%`;

      gsap.to(pupilRef.current, {
        left,
        top,
        duration: 0.2,
        ease: 'power1.out'
      });
    });
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      updateEyePosition({ mouseX: e.clientX, mouseY: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.eye}>
        <div className={styles.pupil} ref={leftPupilRef}></div>
      </div>
      <div className={styles.eye}>
        <div className={styles.pupil} ref={rightPupilRef}></div>
      </div>
    </div>
  );
};

export default Eyes;
