import React from 'react';
import {
  ColorGridTile,
  SliceConfig
} from '@grant/wonderscope-toolkit/components';
import { IntersectionAnimation } from '@grant/wonderscope-common/components';
import { useInView } from 'react-intersection-observer';
import { IColorGrid } from '@grant/wonderscope-sanity';
import cn from 'classnames';
import { useASG } from '@grant/wonderscope-toolkit/hooks';
import * as styles from './styles.module.scss';

interface IProps {
  data: IColorGrid;
}

export interface INewColorDetails {
  title: string;
  hex: string;
}

const ColorGrid = ({ data: { category, sliceConfig } }: IProps) => {
  const ANIMATION_STAGGER = 150;

  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  // const sliceBackgroundColor = sliceConfig.backgroundColor?.hex;

  const {
    colors
    // isAuthenticated
  } = useASG();

  const filteredColors = colors.filter((color) => color.category === category);

  // const [hasUpdatedColors, setHasUpdatedColors] = useState(false);

  // const getUniqueSlug = (title: string) => {
  //   const slug = title.toLowerCase().replace(/\s/g, `-`);

  //   const existingSlug = colors.find((color) => color.slug.current === slug);

  //   if (!existingSlug) return slug;

  //   const recursiveSlugCreation: (slug: string, count?: number) => string = (
  //     slug,
  //     count = 0
  //   ) => {
  //     const newSlug = `${slug}-${count}`;

  //     const existingSlug = colors.find(
  //       (color) => color.slug.current === newSlug
  //     );

  //     if (existingSlug) {
  //       return recursiveSlugCreation(slug, count + 1);
  //     }

  //     return newSlug;
  //   };

  //   return recursiveSlugCreation(slug);
  // };

  // const createNewColor = async (newColorDetails: INewColorDetails) => {
  //   const { hex, title } = newColorDetails;

  //   try {
  //     const newColor: IColor = {
  //       _type: `color`,
  //       title,
  //       hex,
  //       category,
  //       slug: {
  //         current: getUniqueSlug(title)
  //       }
  //     };

  //     const response = await fetch(`/api/create-sanity-color`, {
  //       method: `POST`,
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(newColor)
  //     });
  //     const data = await response.json();

  //     if (data.statusCode === 500) {
  //       throw new Error(`Something went wrong submitting form: ${data.body}`);
  //     }

  //     newColor._id = data._id;

  //     setColors((prevColors) => [...prevColors, newColor]);
  //     setHasUpdatedColors(true);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const getBorderColor = (index: number) => {
  //   const tileColor = filteredColors[index].hex;
  //   if (sliceBackgroundColor?.toLowerCase() !== tileColor.toLowerCase())
  //     return ``;

  //   if (index === 0) {
  //     return filteredColors[1].hex;
  //   }

  //   return filteredColors[index - 1].hex;
  // };

  const isThreeColumn = filteredColors.length < 4;

  // const getAnimationDelay = (index: number) =>
  //   hasUpdatedColors ? 0 : index * ANIMATION_STAGGER;

  return (
    <SliceConfig config={sliceConfig}>
      <IntersectionAnimation>
        <ul
          className={cn(styles.grid, { [styles.threeColumn]: isThreeColumn })}
          ref={ref}
        >
          <>
            {filteredColors.map((color, i) => (
              <IntersectionAnimation
                key={i}
                animation="fadeGrow"
                trigger={inView}
                delay={ANIMATION_STAGGER * i}
              >
                <ColorGridTile
                  isBackgroundColor={
                    color.hex === sliceConfig.backgroundColor?.hex
                  }
                  hex={color.hex}
                  title={color.title}
                />
                {/* <ColorTile
                  color={color}
                  borderColor={getBorderColor(i)}
                  setColors={setColors}
                  getUniqueSlug={getUniqueSlug}
                /> */}
              </IntersectionAnimation>
            ))}

            {/* {isAuthenticated && (
              <IntersectionAnimation
                animation="fadeGrow"
                trigger={inView}
                delay={filteredColors.length * ANIMATION_STAGGER}
              >
                <CreateNewColor createNewColor={createNewColor} />
              </IntersectionAnimation>
            )} */}
          </>
        </ul>
      </IntersectionAnimation>
    </SliceConfig>
  );
};

export default ColorGrid;
