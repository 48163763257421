import React, { useEffect, useState } from 'react';
import { SVG, TSvg } from '@grant/wonderscope-common/components';
import cn from 'classnames';
import { Link } from 'gatsby';
import * as styles from './styles.module.scss';

export interface IButton {
  // Appearance
  text: string;
  // variant?: 'primary' | 'secondary' | 'text' | 'iconButton';
  theme?: 'lightOnDark' | 'darkOnLight';
  iconLeft?: TSvg;
  iconRight?: TSvg;
  disabled?: boolean;
  fluid?: boolean;
  loading?: boolean;
  // Functionality
  href?: string;
  to?: string;
  buttonType?: 'button' | 'reset' | 'submit';
  display?: boolean;
  onClick?: () => void;
  className?: string;
}

const Button = ({
  text,
  buttonType = 'button',
  theme = 'darkOnLight',
  to,
  href,
  iconLeft,
  iconRight,
  disabled,
  fluid,
  loading,
  display,
  onClick,
  className
}: IButton) => {
  const [triggerScramble, setTriggerScramble] = useState(false);

  const handleMouseEnter = () => {
    setTriggerScramble(true);
  };

  let BtnElement: any;
  if (display) BtnElement = 'div';
  else if (to) BtnElement = Link;
  else if (href) BtnElement = 'a';
  else BtnElement = 'button';

  useEffect(() => {
    if (triggerScramble) {
      setTriggerScramble(false);
    }
  }, [triggerScramble]);

  return (
    <BtnElement
      type={to || href ? '' : buttonType}
      href={href}
      onMouseEnter={handleMouseEnter}
      target={href ? '_blank' : null}
      rel={href ? 'noreferrer noopener' : null}
      disabled={disabled}
      onClick={() => onClick && onClick()}
      tabIndex={disabled ? '-1' : 0}
      to={to}
      className={cn('button-text', styles.button, className, {
        [styles.fluid]: fluid,
        [styles.loading]: loading,
        [styles.inverted]: theme === 'lightOnDark'
      })}
    >
      <div className={cn(styles.content, 'label')}>
        {iconLeft && <SVG svg={iconLeft} className={styles.icon} />}

        {text}

        {iconRight && <SVG svg={iconRight} className={styles.icon} />}
      </div>

      {!display && (
        <div className={styles.loaderContainer}>
          <div className={styles.loader} />
        </div>
      )}
    </BtnElement>
  );
};

export default Button;
